import { useEffect, useState } from "react";
import { BASE_CDN_URL, request } from "src/utils/Axios";

import Error404 from "../Errors";
import "../Member/css/PublicProfile.css";
import { Tabs } from "../Tabs";
import { loggedInValidation } from "../Validation";
import { FollowTeamButton } from "../Video/FullPage/CreatorInfo";
import {
  Discord,
  Itchio,
  Patreon,
  Social,
  Subscribestar,
} from "../common/social";
import { generateURL } from "../resizeImages";
import AboutSection from "./Sections/AboutSection";
import GamesSection from "./Sections/GamesSection";
import NewsSection from "./Sections/NewsSection";
import PlaylistsSection from "./Sections/PlaylistSection";
import VideosSection from "./Sections/VideosSection";
import "./css/PublicProfile.css";
import { Avatar } from "../common/Avatar";
import { Helmet } from "react-helmet";

const PublicProfile = ({ match }) => {
  const [teamInfo, setTeamInfo] = useState({});
  const [error, setError] = useState(null);
  const user = match.params.user;
  const loggedIn = loggedInValidation();

  useEffect(() => {
    getTeamInfo();
  }, [user]);

  const getTeamInfo = async () => {
    try {
      const res = await request({
        url: "/api/content-creator-teams/team-profile",
        baseURL: BASE_CDN_URL,
        withCredentials: true,
        params: {
          team_name: user,
        },
      });
      setTeamInfo(res);
    } catch (error) {
      setError(true);
    }
  };

  if (error) return <Error404 />;

  return (
    <main id="team" className="team-public">
      <Helmet>
        <title>{`${teamInfo.name} - Spicygaming`}</title>
        <meta name="description" content={teamInfo.biography} />
      </Helmet>
      <div className="inner">
        <header>
          <div className="logo">
            <Avatar
              src={generateURL(300, 300, teamInfo.profile_picture)}
              width={200}
              name={teamInfo.name}
            />
          </div>
          <div className="details">
            <h1 className="title">
              {teamInfo.name}
              {teamInfo.approved && (
                <span
                  aria-hidden="true"
                  className="material-icons"
                  title="Verified Team"
                >
                  verified
                </span>
              )}
            </h1>
            <div className="description">{teamInfo.biography}</div>
            <div className="social">
              <Social path={teamInfo.facebook_link} fa_name="fa-facebook" />
              <Social path={teamInfo.twitter_link} fa_name="fa-twitter" />
              <Social path={teamInfo.instagram_link} fa_name="fa-instagram" />
              <Social path={teamInfo.website_link} fa_name="fa-globe" />
              <Social path={teamInfo.discord_link}>
                <Discord />
              </Social>
              <Social path={teamInfo.patreon_link} fa_name="">
                <Patreon />
              </Social>
              {/* <Social path={teamInfo.steam_link} fa_name="fa-steam" />
              <Social path={teamInfo.itchio_link}>
                <Itchio />
              </Social> */}
              <Social path={teamInfo.subscribestar_link}>
                <Subscribestar />
              </Social>
            </div>
          </div>
          <br />
          {loggedIn ? (
            <FollowTeamButton id={teamInfo.id} name={teamInfo.name} />
          ) : (
            <div className="button_placeholder"></div>
          )}
        </header>
        <div className="body">
          <Tabs
            withURLChange
            pages={pages}
            firstPageId="games"
            propsForEachPageComponent={{ teamId: teamInfo.id, teamName: user }}
          />
        </div>
      </div>
    </main>
  );
};

const pages = [
  {
    url: "/news",
    displayText: "news",
    component: NewsSection,
    id: "news",
  },
  {
    url: "/games",
    displayText: "games",
    component: GamesSection,
    id: "games",
  },
  {
    url: "/videos",
    displayText: "videos",
    component: VideosSection,
    id: "videos",
  },
  {
    url: "/playlists",
    displayText: "playlists",
    component: PlaylistsSection,
    id: "playlists",
  },
  {
    url: "/about",
    displayText: "about",
    component: AboutSection,
    id: "about",
  },
];

export default PublicProfile;
