import { usePostHog } from "posthog-js/react";
import React, {
  Suspense,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import useQuery from "src/hooks/useQuery";
import { useSpicyStore } from "src/providers/SpicyStoreProvider";
import { convertToSpicyTokens } from "src/utils/currency-format";
import {
  encodeRedirectData,
  reportGameDownload,
} from "src/v2/services/gamesService";
import { getProductPrice } from "src/v2/services/storeService";
import useSWR, { useSWRConfig } from "swr";
import { CONVERSION_RATE } from "../../constants/store";
import { useToggle } from "../../hooks/useToggle";
import { PRODUCT_TYPES } from "../../providers/CartProvider";
import { authRequest } from "../../utils/Axios";
import { getClass } from "../../utils/getClass";
import PaymentOptions from "../BuyTokens/PaymentOptions";
import SelectedPackageDetails from "../BuyTokens/SelectedPackageDetails";
import { SubmitButton } from "../CMS/Common";
import { resourceTypes, types } from "../CMS/Games/Content/types";
import { Chip } from "../Chip";
import { LoaderInside } from "../Common";
import ForceLogin from "../ForceLogin";
import GTags from "../GTags";
import { LocalPrice } from "../LocalPrice";
import { MultiLang } from "../MultiLang";
import { MINIMUM_DIRECT_CHECKOUT_AMOUNT_SG } from "../Payments/Popup";
import { PreviewContext } from "../Previews/Preview";
import useNewTab from "../Previews/hook";
import SpicyGuarantee from "../SpicyGuarantee";
import { loggedInValidation } from "../Validation";
import Popup from "../common/Popup";
import { findAllOSFromResources, osIconFinder } from "../common/osIconFinder";

const PaymentPopup = React.lazy(() => import("../Payments/Popup"));

const DownloadButton = ({
  buttonText,
  UUID,
  gameID,
  onClick,
  resource,
  os = [],
  allowDownload = false,
}) => {
  const previewMode = useContext(PreviewContext);
  const [loading, setLoading] = useState(false);
  const [showSteamKey, toggleSteamKey] = useToggle();

  const [popup, setPopup] = useState(false);
  const [alreadyReported, setAlreadyReported] = useState(false);
  const newTab = useNewTab();

  const notLogged = !loggedInValidation();

  const report = async () => {
    setLoading(true);
    try {
      await reportGameDownload(gameID, UUID);
    } catch {}
    setLoading(false);
    setTimeout(() => {
      setPopup(false);
      setAlreadyReported(true);
    }, 1000);
  };

  const onClickButton = (...args) => {
    if (!allowDownload) return onClick?.(...args);

    switch (resource.type) {
      case types.LINK: {
        const link = new URL(resource.resource);
        if (link.host === "mega.nz" && link.hash) {
          link.hash = link.hash + "/aff=MxC5jxIj0PM";
        }
        link.searchParams.append("utm_source", "spicygamingnet");
        link.searchParams.append("utm_medium", "web");
        link.searchParams.append("utm_campaign", "gamepage");

        const redirectData = encodeRedirectData({
          gameId: gameID,
          redirectLink: link.toString(),
          uuid: UUID,
        });

        const redirectLink = `${
          window.location.origin
        }/redirect?to=${encodeURIComponent(redirectData)}`;

        return newTab.revalidateURL(redirectLink);
      }
      case types.FILE: {
        const a = document.createElement("a");
        a.href = resource.resource;
        a.download = resource.name;
        document.body.appendChild(a);

        const clickHandler = () => {
          setTimeout(() => {
            a.removeEventListener("click", clickHandler);
            document.body.removeChild(a);
          }, 150);
        };
        a.addEventListener("click", clickHandler, false);
        a.click();
        return;
      }
      case types.STEAM: {
        return toggleSteamKey();
      }
      default:
        return;
    }
  };

  if (alreadyReported) return <div>You have already reported this link</div>;

  return (
    <>
      <button
        className={getClass("download-btn", "allowed-download")}
        data-disable={!!previewMode}
      >
        <div className="click-surface" onClick={onClickButton}>
          {buttonText}
          <span>
            {os.map((o, index) => {
              const [icon] = osIconFinder(o);
              return (
                <span data-tooltip={o} key={index}>
                  <span className={getClass("fa icon", icon)}></span>
                </span>
              );
            })}
          </span>
        </div>

        {!notLogged && allowDownload && (
          <div
            title="Use this button to report broken links"
            className="link-broken"
            onClick={() => setPopup((prev) => !prev)}
          >
            Broken?
            <span className="fa fa-chain-broken fa-lg"></span>
          </div>
        )}
      </button>
      <Popup
        className="addSteamKeys"
        open={showSteamKey && resource.type === types.STEAM}
        onClose={toggleSteamKey}
      >
        <h2>Steam key has been sent to your email account now.</h2>
      </Popup>

      <Popup
        open={popup}
        className="download-btn"
        showCloseButton
        onClose={() => setPopup(false)}
      >
        <div className="report-popup">
          <h3>{buttonText} Resource Broken ?</h3>

          <p>
            If the resource is broken please use the report button below to
            notify the Spicygames team.
          </p>
          {loading && <LoaderInside />}
          <div className="buttons">
            <button className="green" onClick={() => setPopup(false)}>
              Back
            </button>
            <button onClick={report}>Report</button>
          </div>
        </div>
      </Popup>
    </>
  );
};

export const Downloads = ({
  gameID,
  disabled,
  download,
  creator,
  allowDownload = false,
  /**
   * this is used to show alreadt purchased downloads
   * the steam keys can be unavailable in that case since the download was purchased
   * but the purchaser has a key he own, so we show the download even when has resource
   * with steam_key_available=false */ showEvenIfNoSteamKeys = false,
}) => {
  const isDiscountedDownload = !isNaN(
    parseFloat(download.discount?.discounted_price)
  );

  const posthog = usePostHog();
  const freeBuyLoading = useRef(false);
  const store = useSpicyStore();
  const swr = useSWRConfig();

  const [showLoginPopup, toggleLoginPopup] = useToggle();
  const [showBuyPopup, toggleBuyPopup] = useToggle(false);
  const [showCCPopup, toggleCCPopup] = useToggle(false);

  const [selectedPaymentMethod, setSelectedPaymentMethod] =
    useState("credit_card");

  const history = useHistory();

  const allSupportOS = findAllOSFromResources(download.resources);

  const searchQuery = useQuery();
  const continueCheckoutProduct = searchQuery.get("continue_checkout_product");

  const initialPrice = download.discount?.discounted_price ?? download.price;

  const latestPriceKey = ["get-product-price", download.id, PRODUCT_TYPES.Game];

  const { data: latestPriceEur } = useSWR(latestPriceKey, () =>
    getProductPrice(download.id, PRODUCT_TYPES.Game, initialPrice)
  );

  const onChangePaymentMethod = (e) => {
    setSelectedPaymentMethod(e.target.value);
  };

  const onProceed = async (data) => {
    if (!selectedPaymentMethod) throw new Error("Payment method not selected");
    try {
      return await authRequest(
        {
          url: "/store/api/members/cart/individual-checkout",
          method: "POST",
          data: {
            data: {
              product_type: PRODUCT_TYPES.Game,
              product_id: download.id,
              price: latestPriceEur,
            },
            billing: data.billing,
            checkout_method: selectedPaymentMethod,
            transaction_token: data?.token,
          },
        },
        true
      );
    } catch (error) {
      if (error.response?.data?.code === "PRICE_CHANGED") {
        swr.mutate(latestPriceKey, latestPriceEur, true);
      }
      if (error.response?.data?.code === "INVALID_DISCOUNT") {
        window.alert(
          "This Sale or Offer has expired. We will refresh the page to get updated pricing."
        );
        window.location.reload();
      }
      throw new Error(error.response?.data?.error ?? error.error);
    }
  };

  const onSuccessfullBuy = async (orderId) => {
    if (orderId) {
      const info = await store.getOrderInfo(orderId);
      store.savePurchaseRecord(info);
    }
    await store.refresh();

    history.push("/library/purchased-games");
  };

  useEffect(() => {
    if (
      // this is used to continue checkout after full refresh
      // due to discount expires in middle of checkouts
      typeof continueCheckoutProduct === "string" &&
      download.id === parseInt(continueCheckoutProduct)
    ) {
      toggleBuyPopup(true);
    }
  }, [continueCheckoutProduct]);

  const buyGame = async () => {
    if (disabled) return;

    posthog.capture("Tokens Pay Button Clicked", {
      tokens_amount: latestPriceEur,
      productId: download.id,
      productType: PRODUCT_TYPES.Game,
    });

    if (!loggedInValidation()) return toggleLoginPopup();
    if (
      initialPrice > store.tokens &&
      initialPrice > MINIMUM_DIRECT_CHECKOUT_AMOUNT_SG
    ) {
      return toggleCCPopup();
    }

    try {
      if (!allowDownload) {
        if (download.price <= 0 && !creator.trusted) {
          if (freeBuyLoading.current) return;
          freeBuyLoading.current = true;
          return await toast.promise(checkoutFreeDownload(), {
            pending: `Buying ${download.name}`,
          });
        }
        return toggleBuyPopup();
      }
    } catch (error) {
      toast.error(error.message);
    } finally {
      // freeBuyLoading.current = false;
    }
  };

  const onPayInstantlyWithTokens = async (amountSG) => {
    const res = await store.payWithSpicyTokens(
      amountSG,
      PRODUCT_TYPES.Game,
      download.id
    );
    if (res.success) {
      toast.success(`You have successfully purchased ${download.game}`);
      history.push("/library/purchased-games");
    }
  };

  const onDonateTokens = async (amountSG) => {
    const res = await authRequest({
      url: "/store/api/teams/donate",
      method: "POST",
      data: {
        team_id: creator?.id,
        token_amt: amountSG,
      },
    });

    posthog?.capture("Creator Support Clicked", {
      creator: creator?.name,
      amount: `${amountSG} SG`,
    });

    if (res.success) {
      toast.success(`You've Successfully donated to ${creator.name}`);
      await checkoutFreeDownload();
    }
  };

  const checkoutFreeDownload = async () => {
    try {
      const res = await store.payWithSpicyTokens(
        0,
        PRODUCT_TYPES.Game,
        download.id
      );

      if (res) {
        toast.success("Successfully bought this game");
        history.push("/library/purchased-games");
      } else {
        toast.error("Error occured. Unable to get this game.");
      }
    } catch (error) {
      posthog.capture("Payment Error", {
        type: "Free Game Buy",
        error: error.message,
      });
      toast.error(error.message);
    } finally {
      toggleBuyPopup(false);
    }
  };

  const hasResourcesWithNoSteamKeys = useMemo(() => {
    return !!download.resources.find(
      (resource) =>
        resource.type === types.STEAM && !resource.steam_key_available
    );
  }, [download]);

  if (download.resources.length <= 0) return null;
  if (hasResourcesWithNoSteamKeys && !showEvenIfNoSteamKeys) return null;

  return (
    <div className="flex" data-disable={disabled}>
      <div itemProp="offers" itemScope itemType="https://schema.org/Offer">
        <link itemProp="availability" href="https://schema.org/InStock" />
        <meta itemProp="priceCurrency" content="EUR" />
        <meta itemProp="price" content={download.price} />
        <meta itemProp="name" content={download.name} />

        <Popup open={showLoginPopup} onClose={toggleLoginPopup}>
          <ForceLogin url={`/game/${gameID}`} />
        </Popup>

        <div className="title">
          <span className="fa fa-download"></span>
          <div className="name" title={download.name}>
            <div>Download {download.name}</div>
            {!!download.discount?.discounted_price && (
              <div className="text-yellow-500 text-base">
                Too Hot to Last! offer ends on{" "}
                <span className="font-semibold">
                  {new Date(download.discount?.expiry).toLocaleDateString(
                    "en-GB",
                    {
                      day: "numeric",
                      month: "long",
                    }
                  )}
                </span>
              </div>
            )}
          </div>

          <span className="download-os">
            {allSupportOS.map((o, index) => {
              const [icon] = osIconFinder(o);
              return (
                <span data-tooltip={o} key={index}>
                  <span className={getClass("fa icon", icon)}></span>
                </span>
              );
            })}
          </span>
        </div>

        {!allowDownload && (
          <div className="download-actions">
            <SpicyGuarantee hideBanner={download.price === 0}>
              <div className="inline-flex items-center ">
                {isDiscountedDownload && (
                  <div className="price !text-4xl !leading-none !font-semibold pr-5 border-r-2 border-r-yellow-500">
                    -{download.discount?.rate}%
                  </div>
                )}
                <div className="px-5">
                  {!allowDownload &&
                    (download.price > 0 ? (
                      isDiscountedDownload ? (
                        <div className="leading-none">
                          <div className="w-fit ml-auto mr-0">
                            <strike>
                              <LocalPrice
                                hideDisclaimer
                                amountInEUR={download.price}
                                showOnlyLocalPrice
                              />
                            </strike>
                          </div>
                          <div className="text-3xl text-[#ffc107] font-medium">
                            <LocalPrice
                              hideDisclaimer
                              amountInEUR={download.discount?.discounted_price}
                              showOnlyLocalPrice
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="price">
                          <LocalPrice
                            hideDisclaimer
                            amountInEUR={download.price}
                            showOnlyLocalPrice
                          />
                        </div>
                      )
                    ) : (
                      <div className="price free">Free</div>
                    ))}
                </div>
                <div>
                  <SubmitButton request={buyGame} label={"Buy"} />
                </div>
              </div>
            </SpicyGuarantee>
          </div>
        )}

        <details className="resources" open={allowDownload === true}>
          <summary>
            {allowDownload ? "Download Resources" : "See Resources"}
          </summary>

          <div>
            {download.resources?.map((resource) => {
              const resourceType = resourceTypes.find(
                (r) => r.type === resource.type
              );

              if (!resourceType) return null;

              return (
                <DownloadButton
                  onClick={buyGame}
                  disabled={disabled}
                  allowDownload={allowDownload}
                  resource={resource}
                  UUID={resource.link_unique_id}
                  key={resource.id}
                  gameID={gameID}
                  os={resource.operating_system}
                  buttonText={
                    <span>
                      {resource.name}
                      <Chip>{resourceType.label}</Chip>
                    </span>
                  }
                />
              );
            })}
          </div>
        </details>
        <Popup
          defaultClose={false}
          showCloseButton
          open={showCCPopup}
          maxWidth={1080}
          onClose={toggleCCPopup}
        >
          <div className="TokenBuyCheckout">
            <SelectedPackageDetails
              productId={download.id}
              productType={PRODUCT_TYPES.Game}
              selectedPkg={{
                title: `Buy ${download.game} - ${download.name}`,
                spicy_tokens: convertToSpicyTokens(initialPrice),
                eur: initialPrice,
              }}
            />

            <PaymentOptions
              onChangePaymentMethod={onChangePaymentMethod}
              selectedPaymentMethod={selectedPaymentMethod}
              onProceed={onProceed}
              onSuccessfullBuy={onSuccessfullBuy}
              selectablePaymentMethods={["coingate", "credit_card"]}
            />
          </div>
        </Popup>

        <Suspense fallback={<LoaderInside />}>
          <PaymentPopup
            productId={download.id}
            productType={PRODUCT_TYPES.Game}
            initialPrice={download.discount?.discounted_price ?? download.price}
            show={download.price > 0 && showBuyPopup}
            onClose={toggleBuyPopup}
            onPayTokens={onPayInstantlyWithTokens}
            title={
              <MultiLang sl={`Kupi ${download.game}`}>
                Buy {download.game} - {download.name}
              </MultiLang>
            }
            description={
              <MultiLang sl={`Prenesi ${download.game}`}>
                Download {download.game}
              </MultiLang>
            }
            supportMsg={
              <MultiLang sl="Podpri razvijalca igre ter dodaj napitnino">
                Support the developer by paying above the minimum price
              </MultiLang>
            }
          />
          <PaymentPopup
            initialPrice={100 / CONVERSION_RATE}
            minimumPrice={10 / CONVERSION_RATE}
            show={creator.trusted && download.price <= 0 && showBuyPopup}
            onClose={toggleBuyPopup}
            title={
              <MultiLang sl={`Podpri ${creator?.name}`}>
                Support {creator?.name}
              </MultiLang>
            }
            description={
              <MultiLang sl="Ta igra je brezplačna, vendar lahko podpreš razvoj igre z donacijo. Doniraj">
                This resource is free but you can still help the developer by
                tossing a coin their way. Tip
              </MultiLang>
            }
            otherDescription={
              <SubmitButton
                request={checkoutFreeDownload}
                label="No thanks, just get me the resource"
                type="secondary"
              />
            }
            supportMsg={
              <MultiLang sl="Podpri razvijalca z napitnino">
                Support the developer by tipping
              </MultiLang>
            }
            onPayTokens={onDonateTokens}
          />
        </Suspense>
      </div>
    </div>
  );
};
