import { useEffect, useState } from "react";
import GoogleLogin from "react-google-login";
import { Helmet } from "react-helmet";
import { Link, useHistory } from "react-router-dom";
import { GOOGLE_CLIENT_ID } from "src/constants/config";
import {
  getGotoRoute,
  saveGotoRoute,
} from "src/utils/keepConsistentRouteAfterLogin";
import { useLanguage } from "../providers/LanguageProvider";
import { request } from "../utils/Axios";
import { SubmitButton } from "./CMS/Common";
import { Loader, passwordToggle } from "./Common";
import { GoogleIcon } from "./common/social";
import "./css/Login.css";
import { Divider } from "./Divider";
import inputFocuser from "./input_focus";
import { MultiLang } from "./MultiLang";
import { Checkbox, inputPlaceholders } from "./Register";
import { usePostHog } from "posthog-js/react";

export const storeCredentials = (res) => {
  localStorage.setItem("username", res.username ? res.username : "");
};

const Login = ({ link, customLink }) => {
  useEffect(() => {
    inputFocuser("main.login .inputs .input input", "main.login button");
  }, []);
  const history = useHistory();
  const posthog = usePostHog();
  const language = useLanguage();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  const [loading, setLoading] = useState(false);

  const socialLogin = async (data) => {
    try {
      setLoading(true);

      saveGotoRoute(link);

      const res = await request({
        method: "post",
        url: "/api/social-login",
        data,
        withCredentials: true,
      });

      posthog.capture("User Logged");

      if (res.success) {
        storeCredentials(res);
        setTimeout(() => {
          window.location.pathname = getGotoRoute();
        }, 1000);
      }
      setError(res.details);
    } catch (error) {
      if (error?.response?.status === 403)
        setError(error?.response?.data?.error);
      else setError(error.message);
    } finally {
      setLoading(false);
    }
  };
  const googleLogin = (res) => {
    var data = new FormData();
    data.append("google_token", res.tokenId);

    socialLogin(data);
  };
  const googleLoginError = (e) => {};

  const clientId = GOOGLE_CLIENT_ID;

  const login = async () => {
    try {
      if (email === "" || password === "") throw new Error("Empty Credentials");
      setError("Requesting...");

      saveGotoRoute(link);

      const res = await request({
        url: "/api/members/login",
        method: "POST",
        withCredentials: true,
        data: {
          remember: rememberMe,
          email,
          password,
        },
      });
      posthog.capture("User Logged");

      if (res["2fa_enabled"] === true) {
        const data = {
          link,
          password,
          customLink,
          method: res.method,
          username: res.username,
          remember: rememberMe,
        };
        return history.push(`/login/2fa`, data);
      }
      storeCredentials(res);

      setTimeout(() => {
        window.location.pathname = getGotoRoute();
      }, 250);

      setError(res.details);
    } catch (error) {
      setError(error.message);
    }
  };

  const input = (value, state) => state(value);

  return (
    <main className="login max-w-xl mx-auto">
      <Helmet>
        <title>Login - Spicygaming</title>
      </Helmet>
      {loading && <Loader />}
      <div className="inner">
        <header>
          <h1>
            <MultiLang sl="PRIJAVA">Log in </MultiLang>
          </h1>
        </header>
        <div
          data-cy="login-message"
          className={`message ${error && "error-msg"}`}
        >
          {error ? (
            error
          ) : (
            <MultiLang sl="Prijavite se z vašimi uporabniškimi podatki">
              Please login with you account credentials
            </MultiLang>
          )}
        </div>
        <div className="inputs">
          <div className="input">
            <div className="fa fa-envelope"></div>
            <input
              autoFocus
              type="email"
              onChange={(e) => input(e.target.value, setEmail)}
              value={email}
              id="login-email"
              data-cy="email"
              placeholder={inputPlaceholders(language, "email")}
            />
          </div>
          <div className="input">
            <div className="fa fa-lock"></div>
            <input
              type="password"
              onChange={(e) => input(e.target.value, setPassword)}
              value={password}
              id="login-password"
              data-cy="password"
              placeholder={inputPlaceholders(language, "password")}
            />
            <span className="fa fa-eye " onClick={passwordToggle}></span>
          </div>
        </div>
        <div className="little-btns">
          <Checkbox
            label={<MultiLang sl="Zapomni se me">Remember Me</MultiLang>}
            id={"login-remember"}
            callback={(_id, checked) => setRememberMe(checked)}
          />
          <Link to="/forgot-password">
            <div className="forgot-password">
              <MultiLang sl="Pozabljeno geslo?">Forgot Password ?</MultiLang>
            </div>
          </Link>
        </div>
        <SubmitButton
          className="login-button"
          request={login}
          label={<MultiLang sl="Prijava">Login</MultiLang>}
        />

        <div className="footer w-full bg-[#121212]">
          <Divider>or login with</Divider>

          <div className="oauth">
            <div className="oauth-btn">
              <GoogleLogin
                clientId={clientId}
                // buttonText="Log in with Google"
                onSuccess={googleLogin}
                onFailure={googleLoginError}
                theme="dark"
                tag="div"
                className="google-btn"
                scope="profile email"
                cookiePolicy={"single_host_origin"}
              >
                <GoogleIcon />
              </GoogleLogin>
            </div>
          </div>
        </div>
        <div className="footer" onClick={() => saveGotoRoute(link)}>
          <div>
            <Link to={"/register"}>Don't have an account? Sign Up</Link>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Login;
