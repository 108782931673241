import { useEffect, useMemo, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { useToggle } from "../../hooks/useToggle";
import { authRequest } from "../../utils/Axios";
import { withoutLastPage } from "../../utils/withoutLastPage";
import { Sort } from "../AdultGames/Header";
import { PaginationSlider } from "../Article/Slider";
import { SubmitButton } from "../CMS/Common";
import { LoaderInside, sort } from "../Common";
import Popup from "../common/Popup";
import { Downloads } from "../GameDetails/DownloadButton";
import { MultiLang } from "../MultiLang";
import "./css/PurchasedGames.css";
import FavGameMini from "./FavGameMini";
import { useSWRConfig } from "swr";
import GTags from "../GTags";
import { usePostHog } from "posthog-js/react";

const sorters = [
  { key: "title", label: "by A-Z" },
  { key: "last_updated", label: "by latest update" },
];

const PuchasedGames = () => {
  const posthog = usePostHog();
  const history = useHistory();
  const [downloads, setDownloads] = useState([]);
  const [games, setGames] = useState([]);
  const [lastPage, setLastPage] = useState(1);
  const [selectedID, setSelectedID] = useState(null);
  const [loading, toggleLoading] = useToggle(true);
  const swr = useSWRConfig();

  const [showPopup, togglePopup] = useToggle();
  const sortConfig = useRef({ key: undefined, order: undefined });

  const selectedGame = useMemo(
    () => games.find((game) => game.id === selectedID),
    [games, selectedID]
  );

  useEffect(() => {
    getGames();
  }, []);

  const getGames = async (page = 1) => {
    try {
      const res = await authRequest({
        url: `/api/members/gamer-profile/purchased-content`,
        params: {
          page,
        },
      });

      await swr.mutate("brought-products", [], true);

      setLastPage(res.find((item) => item.last_page !== undefined)?.last_page);

      const games = res.reduce((prev, curr) => {
        if (prev.find((game) => game.id === curr.id)) return prev;
        return [...prev, curr];
      }, []);

      setGames(sort("last_updated", withoutLastPage(games), "dsc"));
    } catch (error) {
      toast.error(error.message);
    } finally {
      toggleLoading(false);
    }
  };

  const changePage = (number) => {
    getGames(number);
  };

  const sortGames = ({ order, sort: key }) => {
    setGames((prev) => [...sort(key, prev, order)]);
  };

  const sendEvent = (gameName) => {
    GTags.gameClickEvents("download", gameName);
    posthog?.capture("Game Download", { game: gameName });
  };
  const getDownloads = (id, gameName) => async () => {
    try {
      setSelectedID(id);
      sendEvent(gameName);

      const res = await authRequest({
        url: `/api/members/gamer-profile/purchased-content`,
        params: {
          game_id: id,
        },
      });

      setDownloads(res);
      togglePopup(true);
    } catch (error) {
      togglePopup();
      toast.error(error.message);
    }
  };
  const gotoBrowse = () => {
    history.push("/porn-games");
  };

  if (loading) return <LoaderInside />;
  if (!!games.length)
    return (
      <main className="adult-games purchased">
        <button className="sort">
          <span>
            <MultiLang sl="Sortiraj">sort</MultiLang>
            <i className="fa fa-sort-amount-asc"></i>
          </span>
          <div className="menu">
            {sorters.map((sorter) => (
              <Sort
                label={sorter.label}
                sortKey={sorter.key}
                sort={sortGames}
                sortConfig={sortConfig}
                key={sorter.key}
              />
            ))}
          </div>
        </button>
        <div className="purchased-games">
          {games.map((game) => (
            <FavGameMini
              key={game.id}
              {...game}
              button={{
                label: "Download Game",
                onClick: getDownloads(game.id, game?.title),
              }}
            />
          ))}
        </div>

        <Popup
          open={showPopup}
          onClose={togglePopup}
          className="purchased-games-popup"
          showCloseButton
        >
          <h1>Download {selectedGame?.title}</h1>

          <div id="game-detail-info">
            <div className="download-links">
              {downloads.map((download) => (
                <Downloads
                  showEvenIfNoSteamKeys
                  key={download.id}
                  allowDownload
                  gameID={selectedID}
                  download={download.content} // will change
                  creator={{
                    name: selectedGame?.creator,
                    id: selectedGame?.creator_id,
                  }}
                />
              ))}
            </div>
          </div>
        </Popup>

        <PaginationSlider
          total_number_of_pages={lastPage}
          onClick={changePage}
        />
      </main>
    );

  return (
    <div className="no-games">
      <div className="cover">
        <img src="/images/game.svg" alt="buy a game" />
      </div>

      <h2>
        <MultiLang sl="Nisi še kupil/a igre">
          You don't own any games. Yet.
        </MultiLang>
      </h2>
      <br />
      <SubmitButton label="Browse Games" request={gotoBrowse} />
    </div>
  );
};

export default PuchasedGames;
