import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useGamesMetaData } from "../AdultGames/MetaDataProvider";
import { SubmitButton } from "../CMS/Common";
import { Discord, Itchio, Patreon, Social } from "../common/social";
import { follow } from "../Member/FavDeveloperMini";
import { generateURL } from "../resizeImages";
import { loggedInValidation } from "../Validation";
import { FollowTeamButton } from "../Video/FullPage/CreatorInfo";

const Developer = ({ developer, isHorizontal = false }) => {
  const { followed } = useGamesMetaData();
  const [isFollowed, setIsFollowed] = useState(() => {
    return followed.includes(developer.id);
  });

  const followDev = async () => {
    const followed = await follow(developer.id, developer.name)();
    setIsFollowed(!!followed);
  };
  return (
    <div className="game-developer" data-horizontal={isHorizontal}>
      <div className="content">
        <Link to={`/team/@${developer.name}`}>
          <div className="picture">
            <img
              src={
                (developer.profile_picture &&
                  generateURL(200, 200, developer.profile_picture)) ||
                "/images/developer-default.svg"
              }
              alt={developer.name}
            />
          </div>
        </Link>
        <div className="info">
          <Link to={`/team/@${developer.name}`}>
            <h2>{developer.name}</h2>
          </Link>
          <div className="social">
            <Social fa_name="fa-facebook" path={developer.facebook_link} />
            <Social fa_name="fa-instagram" path={developer.instagram_link} />
            <Social fa_name="fa-twitter" path={developer.twitter_link} />
            <Social fa_name="fa-globe" path={developer.website_link} />
            {/* <Social fa_name="fa-steam" path={developer.steam_link} /> */}
            <Social path={developer.patreon_link}>
              <Patreon />
            </Social>
            {/* <Social path={developer.itchio_link}>
              <Itchio />
            </Social> */}
            <Social path={developer.discord_link}>
              <Discord />
            </Social>
          </div>
          <hr color="#414141" />

          {developer.random_game && (
            <Link to={`/game/${developer.random_game.game_id}`}>
              <div className="banner">
                <img
                  src={generateURL(200, 120, developer.random_game?.banner_url)}
                  alt={developer.name}
                />
              </div>
            </Link>
          )}
        </div>
      </div>
      {loggedInValidation() && (
        <FollowTeamButton id={developer.id} name={developer.name} />
      )}
    </div>
  );
};

export default Developer;
